<tbs-title class="mb-2">Company-level Permissions</tbs-title>
<div class="features-table-container">
  <ag-grid-angular
    class="ag-theme-material"
    domLayout="autoHeight"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="sortedProjectFeatures"
    [suppressCellFocus]="true"
    [suppressContextMenu]="true"
    [detailCellRenderer]="detailCellRenderer"
    [detailCellRendererParams]="detailCellRendererParams"
    [detailRowAutoHeight]="true"
    [masterDetail]="true"
    [isRowMaster]="isRowMaster"
    (gridReady)="onGridReady($event)"
  ></ag-grid-angular>
</div>
