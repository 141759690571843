<ag-grid-angular
  class="ag-theme-material"
  domLayout="autoHeight"
  headerHeight="0"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowData]="rowData"
  [suppressCellFocus]="true"
  [suppressContextMenu]="true"
></ag-grid-angular>
