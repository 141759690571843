export enum PictureOptions {
  '2d' = '2d',
  pano = 'pano',
}

export enum ProjectStatusEnum {
  DRAFT = 'draft',
  VERIFICATION_PROCESSING = 'verification_processing',
  VERIFICATION_PROCESSED = 'verification_processed',
  CONSTRUCTION_STARTED = 'construction_started',
  IN_SERVICE = 'in_service',
}

export enum ProjectStatusTextEnum {
  DRAFT = 'Draft',
  VERIFICATION_PROCESSING = 'Verification processing',
  VERIFICATION_PROCESSED = 'Verification processed',
  CONSTRUCTION_STARTED = 'Construction started',
  IN_SERVICE = 'In Service',
}

export enum ProjectStatusDialogEnum {
  DRAFT = 'Draft',
  VERIFICATION = 'Verification',
  CONSTRUCTION_STARTED = 'In Construction',
  IN_SERVICE = 'In Service',
}

export enum ProjectStatusButtonTextEnum {
  SETUP_PROJECT = 'Continue with setup project',
  PROCESSING = 'View project setup',
  PROCESSED = 'Go to my board',
  STARTED = 'Go to project',
  CREATE_PROJECT = 'Create project',
}

export enum MapLayerEnum {
  // MECHANICAL
  HOLES = 'holes',
  PIERS = 'piers',
  TRACKERS = 'trackers',
  TABLES = 'tables',
  MODULES = 'modules',
  // ELECTRICAL
  CBX = 'cbx', // DC Harnesses/CBXs
  DC_HARNESSES = 'dc_harnesses', // DC Feeders AG
  DC_FEEDERS = 'dc_feeders', // DC Feeders UG
  AC_STATIONS = 'ac_stations',
  MV_FEEDERS = 'mv_feeders',
  BESS = 'bess',
  SUBSTATIONS = 'substations',
  // GROUPS
  AREA = 'areas',
  FEEDERS = 'feeders',
  PCS = 'pcs',
  COMBINER_BOXES = 'combiner_boxes',
  SITE = 'sites',
  // CIVIL
  ROADS = 'roads',
  FENCES = 'fences',
  MVT_FENCES = 'boundaries',

  QC_ISSUE = 'qc_issue',
}

export enum WbsScopeEnum {
  ELEMENT_TYPE_ROAD = 'road',
  ELEMENT_TYPE_FENCE = 'fence',
  ELEMENT_TYPE_HOLE = 'hole',
  ELEMENT_TYPE_PIER = 'pier',
  ELEMENT_TYPE_TRACKER = 'tracker',
  ELEMENT_TYPE_TABLE = 'table',
  ELEMENT_TYPE_MODULE = 'module',
  ELEMENT_TYPE_SUBSTATION = 'substation',
  ELEMENT_TYPE_COMBINER_BOX = 'combiner_box',
  ELEMENT_TYPE_DC_FEEDER_AG = 'dc_feeder_ag',
  ELEMENT_TYPE_DC_FEEDER_UG = 'dc_feeder_ug',
  ELEMENT_TYPE_AC_STATION = 'ac_station',
  ELEMENT_TYPE_MV_FEEDER = 'mv_feeder',
  ELEMENT_TYPE_MV_BESS = 'bess',
}

export enum MapLayerGroupEnum {
  CIVIL = 'civil',
  MECHANICAL = 'mechanical',
  ELECTRICAL = 'electrical',
  GROUP_ELEMENTS = 'group_elments',
  SCADA = 'scada',
}

export enum QCIssuesStateEnum {
  DRAFT = 'draft',
  CLOSED = 'closed',
  RESOLVED = 'resolved',
  OPEN = 'opened',
}

export enum QCIssuesStateLabelEnum {
  DRAFT = 'Draft',
  OPEN = 'Opened',
  RESOLVED = 'Remediated',
  CLOSED = 'Verified',
}

export enum WBSEElementTypeEnum {
  MECHANICAL = 'mechanical',
  ELECTRICAL = 'electrical',
  CIVIL = 'civil',
  GROUPS = 'groups',
}

export enum FloatingGuaranteedMilestone {
  FLOATING = 'floating',
  GUARANTEED = 'guaranteed',
}

export enum DictionaryPipeType {
  SINGULAR = 'singular',
  PLURAL = 'plural',
  ABBREVIATION = 'abbreviation',
}

export enum BaseMapType {
  SAT = 'sat',
  DARK = 'dark',
  TOPO = 'topo',
  OSM = 'osm',
}

export enum DigitalTwinMapMultipleOptions {
  MULTIPLE_SELECT = 'multipleSelect',
  MULTIPLE_DESELECT = 'multipleDeselect',
}

export enum GeometryTypeEnum {
  Length = 'LineString',
  Point = 'Point',
  Area = 'Polygon',
}

export enum MvCircuitStatusColor {
  IN_SERVICE_EPC_BORDER = '#B26F21',
  IN_SERVICE_EPC_FILL = '#FE9E2F',
  IN_SERVICE_OM_BORDER = '#45731F',
  IN_SERVICE_OM_FILL = '#E1EFD6',
  HOT_COMMISSIONING_BORDER = '#E09400',
  HOT_COMMISSIONING_FILL = '#FFCC69',
  IN_CONSTRUCTION_BORDER = '#2B5390',
  IN_CONSTRUCTION_FILL = '#8098BC',
  NOT_STARTED_BORDER = '#808080',
  NOT_STARTED_FILL = '#E4E4E4',
}

export enum ProjectDataSource {
  DUAL = 'dual',
  OWNER = 'owner',
  EPC = 'epc',
}

export enum MeasurementUnits {
  Metric = 'metric',
  Imperial = 'imperial',
}

export enum DroneOrthoSourcesEnum {
  Maptiler = 'maptiler',
  S3 = 's3',
  S3Direct = 's3direct',
}

export enum InstallationElementsLegendEnum {
  NOT_INSTALLED = 'Not Installed (IFC)',
  PLAN = 'Plan',
  IN_PROGRESS = 'In Progress',
  INSTALLED = 'Installed',
}

export enum InstallationProgressEnum {
  IFC = 'ifc',
  PLANNED = 'planned',
  IN_PROGRESS = 'inProgress',
  INSTALLED = 'installed',
}

export enum ConstructionProjectFeaturesNameEnum {
  LOGISTICS_INVENTORY = 'Logistics & Inventory',
  QC_ISSUES = 'QC Observations',
  DIGITAL_TWIN = 'Progress Tracking',
  PRODUCTION_TRACKING = 'Production Tracking',
  EQUIPMENT_TRACKING = 'Equipment Tracking',
  APPROVALS = 'Approvals',
  OMEGA = 'Digital POD',
  MV_CIRCUIT = 'MV Circuit Hand Off',
  ADVANCED_DETECTION = 'Advanced Detection',
  TERAFAB = 'Terafab',
}

export enum ConstructionProjectFeaturesValuesEnum {
  LOGISTICS_INVENTORY = 'logistics_inventory',
  QC_ISSUES = 'qc_issues',
  DIGITAL_TWIN = 'digital_twin',
  PRODUCTION_TRACKING = 'production_tracking',
  EQUIPMENT_TRACKING = 'equipment_tracking',
  APPROVALS = 'approvals',
  OMEGA = 'omega',
  MV_CIRCUIT = 'mv_circuit',
  ADVANCED_DETECTION = 'advanced_detection',
  TERAFAB = 'terafab',
}

export enum ConstructionProjectFeaturesOrderEnum {
  'logistics_inventory' = 0,
  'digital_twin',
  'production_tracking',
  'equipment_tracking',
  'omega',
  'advanced_detection',
  'qc_issues',
  'approvals',
  'mv_circuit',
  'terafab',
}

export type WBSElementDataType = {
  id: string;
  layer: string;
  layerName?: string;
  name: string;
  built_at?: any;
  [k: string]: any;
};

export type IWBSElementDef = {
  label: string;
  layer: string;
  type: string;
  group: boolean;
  elements: WBSElementDataType[];
  flagged?: boolean;
  dictionaryLabel?: string;
};

export type SectionLabelsType = {
  civil: string;
  mechanical: string;
  electrical: string;
  group: string;
};

export enum WBSElementType {
  ELECTRICAL = 'electrical',
  MECHANICAL = 'mechanical',
  GROUPING = 'grouping',
  ORGANIZATIONAL = 'organizational',
  CIVIL = 'civil',
}
