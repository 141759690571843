import { Component } from '@angular/core';
import {
  MatLegacySlideToggleModule,
  MatLegacySlideToggleChange as MatSlideToggleChange,
} from '@angular/material/legacy-slide-toggle';
import { IToggleCell } from '@construction/modules/project-edit/projects.types';
import { TbsSlideToggleModule } from '@ngx-terabase/ui/slide-toggle';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cell-toggle',
  templateUrl: './cell-toggle.component.html',
  styleUrls: ['./cell-toggle.component.scss'],
  standalone: true,
  imports: [MatLegacySlideToggleModule, TbsSlideToggleModule],
})
export class CellToggleComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams & IToggleCell;
  public isChecked = false;
  public isDisabled = false;

  public agInit(params: ICellRendererParams & IToggleCell): void {
    this.params = params;
    this.isChecked = this.params.isChecked;
    if (this.params.isDisabled) {
      this.isDisabled = this.params.isDisabled;
    }
  }

  public refresh(): boolean {
    return true;
  }

  public toggleCheck(event: MatSlideToggleChange) {
    this.isChecked = event.checked;
    this.params.change(event.checked);
  }
}
