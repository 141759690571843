import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterModel, SortModelItem } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class ServerSideTableService {
  public addFiltersToParams(filters: FilterModel, params: HttpParams) {
    Object.keys(filters).forEach((key) => {
      switch (filters[key].filterType) {
        case 'date':
          return (params = params.append(key, filters[key].dateFrom));
        case 'text':
          return (params = params.append(key, filters[key].filter));
        case 'set':
          return (params = params.append(key, filters[key].values.join(',').toLowerCase()));
        default:
          return;
      }
    });

    return params;
  }

  public changeSortColumnsName(sortModel: SortModelItem): string {
    switch (sortModel.colId) {
      case 'inspection_key':
        return 'inspection_number';
      case 'statuses':
        return 'status';
      case 'qc_subscope_uuids':
        return 'qc_subscope';
      case 'qc_scope_uuids':
        return 'qc_scope';

      default:
        return sortModel.colId;
    }
  }
}
