import { CellToggleComponent } from '@ag-grid/cell-templates/cell-toggle/cell-toggle.component';
import { Component } from '@angular/core';
import { ConstructionProjectFeaturesType, PerspectiveDataType } from '@construction/models/construction-project.model';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { EnabledFeature, PerspectiveChangeParam } from '../construction-features-list.component';

@Component({
  selector: 'perspective-selection',
  templateUrl: './perspective-selection.component.html',
  styleUrls: ['./perspective-selection.component.scss'],
})
export class PerspectiveSelectionComponent implements ICellRendererAngularComp {
  public nameMap: Record<string, string> = {
    aerial_data: 'Aerial Data',
    ground_data: 'Ground Data',
  };

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 90,
    resizable: false,
    suppressMenu: true,
    suppressMovable: true,
  };

  public columnDefs: ColDef[] = [
    {
      field: '',
      minWidth: 50,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Feature',
      minWidth: 200,
      sortable: false,
      valueGetter: (params: ValueGetterParams<PerspectiveDataType>) => {
        if (!params.data) return '';

        return this.nameMap[params.data.name];
      },
    },
    {
      field: 'owner_enabled',
      headerName: 'Owner',
      maxWidth: 100,
      sortable: false,
      cellRendererSelector: (params) => {
        return {
          component: CellToggleComponent,
          params: {
            isChecked: params.value,
            change: (checked: boolean) => {
              const perspective = params.data;
              perspective.owner_enabled = checked;
              this.switchToggled(this.feature, EnabledFeature.OwnerEnabled);
            },
          },
        };
      },
    },
    {
      field: 'epc_enabled',
      headerName: 'EPC',
      maxWidth: 100,
      sortable: false,
      cellRendererSelector: (params) => {
        return {
          component: CellToggleComponent,
          params: {
            isChecked: params.value,
            change: (checked: boolean) => {
              const perspective = params.data;
              perspective.epc_enabled = checked;
              this.switchToggled(this.feature, EnabledFeature.EpcEnabled);
            },
          },
        };
      },
    },
    {
      field: 'supplier_enabled',
      headerName: 'Supplier',
      maxWidth: 100,
      sortable: false,
      cellRendererSelector: (params) => {
        return {
          component: CellToggleComponent,
          params: {
            isChecked: params.value,
            isDisabled: true,
            change: (checked: boolean) => {
              const perspective = params.data;
              perspective.supplier_enabled = checked;
              this.switchToggled(this.feature, EnabledFeature.SupplierEnabled);
            },
          },
        };
      },
    },
  ];

  public rowData: PerspectiveDataType[] = [];

  private params: ICellRendererParams<ConstructionProjectFeaturesType> & PerspectiveChangeParam;
  private feature: ConstructionProjectFeaturesType;

  public agInit(params: ICellRendererParams<ConstructionProjectFeaturesType> & PerspectiveChangeParam): void {
    this.params = params;

    if (params.data) {
      this.feature = params.data;
      this.rowData.push(params.data.aerial_data!);
      this.rowData.push(params.data.ground_data!);
    }
  }

  public refresh(): boolean {
    return false;
  }

  private switchToggled(feature: ConstructionProjectFeaturesType, role: EnabledFeature): void {
    this.params.perspectiveChange(feature, role);
  }
}
