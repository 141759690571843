export enum LocalStorageKeyEnum {
  WeekLabel = 'weekLabel',
  KeepLoggedIn = 'keepLoggedIn',
  FirstLogin = 'first_login',
  UserImage = 'user_image',
  TerabaseAuth = 'terabase_auth',
  CompanyLogoImage = 'company_logo_image',
  CompanyImage = 'company_image',
  WeatherUnit = 'weather_unit',
  ShowProjectsList = 'showProjectsList',
  ProjectView = 'projectView',
  ProgressOverviewOpen = 'ProgressOverviewOpen',
  SlicerPreset = 'slicerPreset',
  SingleSlicerPreset = 'singleSlicerPreset',
  IsSidebarShrinked = 'isSidebarShrinked',
}
