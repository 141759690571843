import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { Angulartics2Module } from 'angulartics2';
import 'ag-grid-enterprise';
import 'moment-timezone';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule } from '@core/core.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { LayoutModule } from '@layout/layout.module';
import { TbsNotificationModule } from '@ngx-terabase/ui/notification';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { ErrorResponseDialogComponent } from '@shared/error-response-dialog/error-response-dialog.component';
import { BaseRouteReuseStrategy, RouteReuseStrategy } from '@angular/router';

class AppRoutingStrategyService extends BaseRouteReuseStrategy {
  public override shouldReuseRoute(): boolean {
    return false;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgxSpinnerModule,
    TbsNotificationModule,
    Angulartics2Module.forRoot({ pageTracking: { clearIds: true, clearQueryParams: true, clearHash: true } }),
    CoreModule.forRoot(),
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: ['development', 'development-test', 'staging', 'production'].includes(environment.name),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ErrorResponseDialogComponent,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: AppRoutingStrategyService }],
  bootstrap: [AppComponent],
})
export class AppModule {}
